module.exports = {
  // cookie keys
  COOKIE_JWT_KEY: 'ME_jwt',
  COOKIE_JWT_SIGNATURE_KEY: 'ME_jwt_sig',
  COOKIE_TESTING_KEY: 'ME_testing_key',
  COOKIE_CURRENCY_KEY: 'ME_currency',
  COOKIE_LANGUAGE_KEY: 'ME_lang',
  COOKIE_CLIENT_ID_KEY: 'ME_cid',
  COOKIE_UI_MODE_KEY: 'ME_ui_mode',


  // vuex-persistence keys
  // account
  STORAGE_VUEX_ACCOUNT_VERSION: 'v230612',
  STORAGE_VUEX_ACCOUNT_KEY: 'me:vuex:account',
  // currency
  STORAGE_VUEX_CURRENCY_VERSION: 'v230612',
  STORAGE_VUEX_CURRENCY_KEY: 'me:vuex:currency',

  STORAGE_VUEX_EXPERIMENT_VERSION: 'v230612',
  STORAGE_VUEX_EXPERIMENT_KEY: 'me:vuex:experiment',

  // search
  STORAGE_VUEX_SEARCH_BOX_VERSION: 'v210400',
  STORAGE_VUEX_SEARCH_BOX_KEY: 'me:vuex:search_box',
  STORAGE_VUEX_SEARCH_PREF_VERSION: 'v210400',
  STORAGE_VUEX_SEARCH_PREF_KEY: 'me:vuex:search',

  // library preferences
  STORAGE_VUEX_LIBRARY_PREF_VERSION: 'v230612',
  STORAGE_VUEX_LIBRARY_PREF_KEY: 'me:vuex:library',

  // reseller
  STORAGE_VUEX_RESELLER_VERSION: 'v210400',
  STORAGE_VUEX_RESELLER_INVOICES_KEY: 'me:vuex:reseller:invoices',

  // admin
  STORAGE_VUEX_ADMIN_VERSION: 'v210630',
  STORAGE_VUEX_ADMIN_INVOICES_KEY: 'me:vuex:admin:invoices',

  // cart
  STORAGE_VUEX_CART_VERSION: 'v230720',
  STORAGE_VUEX_CART_KEY: 'me:vuex:cart',

  // favorites
  STORAGE_VUEX_FAVORITES_VERSION: 'v230612',
  STORAGE_VUEX_FAVORITES_KEY: 'me:vuex:favorites',

  // audioPlayer
  STORAGE_VUEX_AUDIO_PLAYER_VERSION: 'v210608',
  STORAGE_VUEX_AUDIO_PLAYER_KEY: 'me:vuex:audio_player',

  // navbar preference
  STORAGE_VUEX_NAVBAR_PREF_VERSION: 'v230612',
  STORAGE_VUEX_NAVBAR_PREF_KEY: 'me:vuex:navbar_preference',
};
