import Cookies from 'js-cookie';
import uuid from 'uuid/v4';
import * as accountApi from '@motionelements/core/src/api/account.js';
import * as programsApi from '@motionelements/core/src/api/programs.js';
import * as subscriptionApi from '@motionelements/core/src/api/subscription.js';
import { getJwtPayload } from '@motionelements/core/src/services/jwt.service';
// import store from '@/store/index.js';
import notificationService from '@motionelements/core/src/services/notification.service';
import { redirectToLanguageUrl, refreshJwtLanguage } from '@motionelements/core/src/services/language.service.js';
import link from '@motionelements/core/src/helpers/link';

import {
  COOKIE_JWT_KEY,
  COOKIE_JWT_SIGNATURE_KEY,
  COOKIE_CURRENCY_KEY,
  COOKIE_LANGUAGE_KEY,
  COOKIE_CLIENT_ID_KEY,
  COOKIE_UI_MODE_KEY,
  // vuex-persistence keys
  STORAGE_VUEX_ACCOUNT_KEY,
  STORAGE_VUEX_ACCOUNT_VERSION,
  STORAGE_VUEX_CURRENCY_KEY,
  STORAGE_VUEX_SEARCH_BOX_KEY,
  STORAGE_VUEX_SEARCH_BOX_VERSION,
  STORAGE_VUEX_CART_KEY,
  STORAGE_VUEX_CART_VERSION,
  STORAGE_VUEX_FAVORITES_KEY,
  STORAGE_VUEX_FAVORITES_VERSION,
} from '@motionelements/core/src/app.constants.js';

// cache jwt payload
const jwtPayload = getJwtPayload();

let store;
// Nuxt compatibility workaround
export function setStore(s) {
  // if (!store) store = s;
  store = s;
  // console.log('account set store', s);
}

export function isExContributor() {
  return _.get(jwtPayload, 'roles', []).includes('ex_contributor');
}

function setCookie(name, value, expires) {
  // Cookies.set(key, value, { path: '/', domain: '.motionelements.com', secure: true });

  Cookies.set(name, value, {
    expires: expires || null, // 400 days (in days, not seconds like max-age)
    domain: '.motionelements.com', // Ensure it's a valid domain
    secure: true,
  });

  if (process.env.VUE_APP_ENV !== 'production') {
  // if (process.env.NODE_ENV === 'development') {
    Cookies.set(name, value); // localhost fallback
  }
}

function removeCookie(name) {
  Cookies.remove(name, {
    domain: '.motionelements.com',
    secure: true,
  });

  if (process.env.VUE_APP_ENV !== 'production') {
    Cookies.remove(name);
  }
}

function getCookieCurrencyCode() {
  let cookieCurrencyCode = _.get(jwtPayload, 'currency', null) || Cookies.get(COOKIE_CURRENCY_KEY);
  cookieCurrencyCode = cookieCurrencyCode ? cookieCurrencyCode.toUpperCase() : null;
  return cookieCurrencyCode;
}

function getSession(KEY, hash) {
  if (!process.server) {
    let session = JSON.parse(sessionStorage.getItem(KEY));

    if (session) {
      if (hash !== session.hash) {
        sessionStorage.removeItem(KEY);
        session = null;
      }
      return session;
    }
  }
}

function getAccountSession() {
  if (!process.server) {
    let accountSession = JSON.parse(sessionStorage.getItem(STORAGE_VUEX_ACCOUNT_KEY));
    if (accountSession) {
      // console.log(store.state.user.jwt.id);
      // console.log(store.state.user.language);
      // console.log(store.state.user.currencyCode);
      // const jwtId = `${user.jwt.id}|${user.language}|202102`,
      // const languageCode = store.getters['user/languageCode'];
      // const currencyCode = store.getters['user/currencyCode'];
      let cookieCurrencyCode = _.get(jwtPayload, 'currency', null) || Cookies.get(COOKIE_CURRENCY_KEY);
      cookieCurrencyCode = cookieCurrencyCode ? cookieCurrencyCode.toUpperCase() : null;
      const hash = `${store.state.user.jwt.id}|${store.state.user.language}|${cookieCurrencyCode}|${STORAGE_VUEX_ACCOUNT_VERSION}`;
      if (hash !== accountSession.hash) {
        sessionStorage.removeItem(STORAGE_VUEX_ACCOUNT_KEY);
        accountSession = null;
      }
      return accountSession;
    }
  }
}




export async function getAccount() {
  return accountApi.getAccount().then(res => res.data.data);
}

export async function updateAccount(params) {
  return accountApi.updateAccount(params).then((response) => {
    const data = response.data.data;
    store.dispatch('user/setMember', data);
    if (_.has(data, 'currency')) {
      store.dispatch('user/setCurrencyCode', data.currency);
    }
    if (_.has(data, 'language')) {
      redirectToLanguageUrl(data.language);
      // store.dispatch('user/setLanguage', data.language);
    }
  });
}

export function getLanguageCode() {
  return _.get(jwtPayload, 'language', null);
}

export function getCurrencyCode() {
  return _.get(jwtPayload, 'currency', null);
}

export function isLoggedIn() {
  return _.has(jwtPayload, 'sub');
}

export function getCookieLanguageCode() {
  if (isLoggedIn()) {
    return _.get(jwtPayload, 'language', null);
  }
  return Cookies.get(COOKIE_LANGUAGE_KEY);
}

export function getInitialUiMode() {
  return Cookies.get(COOKIE_UI_MODE_KEY) || 'light';
  // @todo check prefers-color-scheme work on all browsers
  //   || window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
}


export function setUiMode(mode) {
  mode = mode === 'dark' ? 'dark' : 'light';

  setCookie(COOKIE_UI_MODE_KEY, mode, 7);

  if (mode === 'dark') {
    document.documentElement.classList.add('ui-dark');
  } else {
    document.documentElement.classList.remove('ui-dark');
  }
}



// called from vuex
// handle non vuex related functions
export function changeCurrency(currencyCode) {
  currencyCode = currencyCode.toUpperCase();

  setCookie(COOKIE_CURRENCY_KEY, currencyCode, 7);

  if (isLoggedIn()) {
    // update lazily
    accountApi.updateAccount({
      currency: currencyCode,
    })
      .then(() => {
        window.location.reload(true);
      });
  } else {
    window.location.reload(true);
  }
}

// called from vuex
// handle non vuex related functions
export function changeUiMode(mode) {
  setUiMode(mode);

  if (isLoggedIn()) {
    // update lazily
    accountApi.updateAccount({
      ui_mode: mode,
    })
      .then((response) => {
        console.log(response);
        // redirectToLanguageUrl(languageCode);
      });
  }
}

// eslint-disable-next-line no-unused-vars
export function updateGoogleTagManager(data) {
  if (!process.server && typeof window.dataLayer !== 'undefined') {
    let memberType = 'member';
    if (data.admin) {
      memberType = 'admin';
    } else if (data.reseller) {
      memberType = 'reseller';
    } else if (data.artist) {
      memberType = 'artist';
    }

    const gtmData = {
      userId: data.id,
      username: data.username,
      firstName: data.firstName,
      lastName: data.lastName,
      fullName: data.fullName,
      email: data.email,
      memberType: memberType,
      internal: data.internal,
      language: data.language,
    };

    window.dataLayer.push(gtmData);
  }
}

// eslint-disable-next-line no-unused-vars
function mapJwtData(jwtPayload) {
  return {
    id: jwtPayload.sub,
    fullName: _.get(jwtPayload, 'name'),
    firstName: _.get(jwtPayload, 'given_name'),
    lastName: _.get(jwtPayload, 'family_name'),
    username: _.get(jwtPayload, 'preferred_username'),
    language: _.get(jwtPayload, 'language', 'en'),
    currency: _.get(jwtPayload, 'currency', 'USD'),
    email: _.get(jwtPayload, 'email'),
    avatar: {
      url: _.get(jwtPayload, 'picture'),
    },
    subscriptions: _.get(jwtPayload, 'subscriptions'),
    artist: _.get(jwtPayload, 'artist'),
    internal: _.get(jwtPayload, 'internal', false),
    roles: _.get(jwtPayload, 'roles', []),
  };
}

function initCart() {
  const hash = `${store.state.user.jwt.id}|${store.state.user.language}|${getCookieCurrencyCode()}|${STORAGE_VUEX_CART_VERSION}`;
  const cartSession = getSession(STORAGE_VUEX_CART_KEY, hash);
  if (cartSession) {
    store.commit('cart/setNavCartItems', cartSession.cart.navItems);
    store.commit('cart/setTotalCount', cartSession.cart.totalItemsCount);
  } else {
    store.dispatch('cart/listNavItems');
  }
}

function initFavorites() {
  const hash = `${store.state.user.jwt.id}|${store.state.user.language}|${getCookieCurrencyCode()}|${STORAGE_VUEX_FAVORITES_VERSION}`;
  const favoritesSession = getSession(STORAGE_VUEX_FAVORITES_VERSION, hash);
  if (favoritesSession) {
    store.commit('favorites/updateAddedToday', favoritesSession.favorites.addedToday);
  }
}

function initSearchBoxLocalStorage() {
  if (!process.server) {
    const data = JSON.parse(localStorage.getItem(STORAGE_VUEX_SEARCH_BOX_KEY));

    if (data) {
      const hash = `${store.state.user.jwt.id}|${STORAGE_VUEX_SEARCH_BOX_VERSION}`;

      const isExpired = data.modifiedAt < Math.round(Date.now() / 1000) - (7 * 24 * 60 * 60); // 1 week ago

      if (isExpired || hash !== data.hash) {
        localStorage.removeItem(STORAGE_VUEX_SEARCH_BOX_KEY);
        store.commit('searchBox/deleteAll'); // clear search-box history
      }
    }
  }
}

export function refreshAccount() {
  store.commit('user/setLoading', true);
  return accountApi.getAccount({ 'fields[member]': 'artist_details' }).then((response) => {
    store.commit('user/setLoading', false);
    // response.data.data.emailVerified = true;
    const data = response.data.data;
    // data.admin = false;

    if (data) {
      // check if route language is same as account language?
      if (getCookieLanguageCode() !== data.language) {
        console.log('LANG DIFF', getCookieLanguageCode(), data.language);
        refreshJwtLanguage(data.language);
      }

      // console.log('get member info: ', data);
      // console.log('setCurrencyCode', data.currency);
      // store.dispatch('user/setCurrencyCode', data.currency);
      store.dispatch('user/setMember', data);
    }

    // only run initCart & initFavorites for buyer site
    const projectName = process.env.VUE_APP_PROJECT_NAME;
    const isBuyerSite = !['frontend-reseller', 'frontend-admin'].includes(projectName);
    if (isBuyerSite) {
      initCart();
      initFavorites();
    }

    return response;
  }).catch((error) => {
    console.error(error);
    store.commit('user/setLoading', false);
    store.commit('user/setError', true);
    if (error.response.status === 401) {
      // unset JWT
      // need to specify full path and domain
      removeCookie(COOKIE_JWT_KEY);
      removeCookie(COOKIE_JWT_SIGNATURE_KEY);

      store.dispatch('user/clearMember');
      notificationService.handleResponseNotification({
        data: {
          messages: [
            {
              type: 'alert',
              level: 'info',
              title: 'Login again',
              details: 'Please login again.',
            },
          ],
        },
      });

      const doRedirectToLoginPage = true;

      if (doRedirectToLoginPage) {
        // route to login only if it's not login
        const currentPath = store.getters['route/fullPath'];

        if (currentPath.indexOf('/account/login') === -1) {
          window.location.href = link('/account/login').param('intended_url', currentPath).url();
        } else {
          console.log('skip redirect to same login page');
        }
      }
    }
  });
}

function initClientId() {
  if (!Cookies.get(COOKIE_CLIENT_ID_KEY)) {
    // Create a cookie named 'ME_cid' with a value of clientId or a generated UUID
    setCookie(COOKIE_CLIENT_ID_KEY, uuid(), 400); // 400 days
    // Cookies.set(COOKIE_CLIENT_ID_KEY, uuid(), {
    //   domain: 'motionelements.com', // Ensure it's a valid domain
    //   expires: 400, // 400 days (in days, not seconds like max-age)
    //   path: '/',
    //   sameSite: 'Strict',
    //   secure: true,
    // });
  }
}

// initAccount via API
export function initAccount(vm) {
  if (isLoggedIn() && jwtPayload) {
    // clientId should be created for loggedIn

    // map data from standard JWT fields
    const data = mapJwtData(jwtPayload);
    console.log(data);

    // jwt id
    const jwtId = _.get(jwtPayload, 'jti');
    // use as session id
    store.commit('user/setJwtId', jwtId);
    // set jwt member for temp
    store.commit('user/setJwtMember', data);

    updateGoogleTagManager(data);
  }
  initSearchBoxLocalStorage();
  // initCart();

  if (!isLoggedIn()) {
    // clear account session storage
    // set final currency from cookie
    if (!process.server) {
      initClientId();
      sessionStorage.removeItem(STORAGE_VUEX_ACCOUNT_KEY);
    }
    const cookieCurrencyCode = Cookies.get(COOKIE_CURRENCY_KEY);
    if (cookieCurrencyCode) {
      store.dispatch('user/setCurrencyCode', cookieCurrencyCode);
    }
    sessionStorage.removeItem(STORAGE_VUEX_CART_KEY);
    sessionStorage.removeItem(STORAGE_VUEX_FAVORITES_KEY);
    return null;
  }

  const accountSession = getAccountSession();
  if (!_.get(accountSession, 'user.isLoaded')) {
    return refreshAccount();
  }
  // store.dispatch('user/setCurrencyCode', accountSession.user.currencyCode);
  // store.dispatch('user/setLanguage', accountSession.user.language);
  store.dispatch('user/setMember', accountSession.user.member);
  // SET ROLLBAR MEMBER

  const rollbarPerson = {
    id: accountSession.user.member.id, // required
    username: accountSession.user.member.username,
    email: accountSession.user.member.email,
  };
  vm.$rollbar.configure({
    payload: {
      person: rollbarPerson,
    },
  });


  // store.commit('favorites/setDefaultFolderTotalCount', accountSession.favorites.defaultFolder.totalCount);
  initFavorites();
  initCart();
  // store.commit('cart/setCartItems', accountSession.cart.items);
}

export async function signUp(payload) {
  return accountApi.signUp(payload);
}

export async function resendVerifyEmail() {
  return accountApi.resendVerifyEmail();
}

export async function listMemberPrograms(payload) {
  return programsApi.listMemberPrograms(payload);
}

// PROFILE

export async function listSubscriptions(payload) {
  return subscriptionApi.listSubscriptions(payload);
}

export function clearSessions() {
  sessionStorage.removeItem(STORAGE_VUEX_CURRENCY_KEY);
  sessionStorage.removeItem(STORAGE_VUEX_ACCOUNT_KEY);
}

export async function updateSubscription(subId, payload) {
  return subscriptionApi.updateSubscription(subId, payload);
}

export async function cancelSubscription(subId) {
  clearSessions();
  return subscriptionApi.cancelSubscription(subId);
}

export async function getAccountProfileCompletion() {
  return accountApi.getAccountProfileCompletion();
}

export async function getAccountProfileDetails() {
  return accountApi.getAccountProfileDetails();
}

export async function getPayoneerPayee() {
  return accountApi.getPayoneerPayee();
}

export async function createPayoneerPayee(payload) {
  return accountApi.createPayoneerPayee(payload);
}

export async function listSubscriptionPlans() {
  return accountApi.listSubscriptionPlans();
}

export async function getSubscriptionPlan(payload) {
  return accountApi.getSubscriptionPlan(payload);
}

export async function updateAvatar(payload) {
  return accountApi.updateAvatar(payload);
}
